$theme: core;
@use "design-system" as ds;
@use "sass:math";

.headerLogo {
  pointer-events: all;
}

.coreLogo {
  background-image: url(/images/logo-core.png);
  background-size: contain;
  width: 70px;
  height: 22px;
  background-repeat: no-repeat;

  @include ds.darkmode {
    background-image: url(/images/logo-core-darkmode.png);
  }

  @media (min-width: ds.$screen-size--small) {
    width: math.ceil(70px * ds.$desktop-upscaling-factor);
    height: math.ceil(22px * ds.$desktop-upscaling-factor);
  }
}

.economyLogo {
  background-image: url(/images/logo-ekonomi.png);
  background-size: contain;
  width: 179px;
  height: 22px;
  margin: auto 0;
  background-repeat: no-repeat;

  @include ds.darkmode {
    background-image: url(/images/logo-ekonomi-darkmode.png);
  }

  @media (min-width: ds.$screen-size--small) {
    width: math.ceil(179px * ds.$desktop-upscaling-factor);
    height: math.ceil(22px * ds.$desktop-upscaling-factor);
  }
}

.hiddenText {
  @include ds.a11y-hidden();
}
